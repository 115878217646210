import * as i from 'types';
import React from 'react';

import { Checkbox, FormField } from '../';

export const InputCheckbox: React.FC<InputCheckboxProps> = ({
  name, label, error, description, onChange, children, value, checkboxLabel, direction,
}) => {
  const handleOnChange = () => onChange(!value);

  return (
    <FormField {...{ label, name, error, description }}>
      <Checkbox
        label={checkboxLabel}
        direction={direction}
        onChange={handleOnChange}
        isActive={value}
      >
        {children}
      </Checkbox>
    </FormField>
  );
};

export type InputCheckboxProps = i.FormFieldProps & {
  checkboxLabel?: string;
  onChange: (value: boolean) => void;
  value: boolean;
  direction?: 'horizontal' | 'vertical';
  name?: string,
  label?: string,
};
