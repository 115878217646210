import React from 'react';

import { Container, Circle, Check, CheckboxContainer, Label } from './styled';

export const Checkbox: React.FC<CheckboxProps> = ({
  isRadio, isActive, direction, onChange, label, children,
}) => {
  return (
    <CheckboxContainer onClick={onChange} direction={direction}>
      <Container isRadio={isRadio} isActive={isActive}>
        {!isRadio && isActive && <Check />}
        {isRadio && isActive && <Circle />}
      </Container>
      {!children && label && <Label>{label}</Label>}
      {children}
    </CheckboxContainer>
  );
};

export type CheckboxProps = {
  isRadio?: boolean;
  isActive?: boolean;
  label?: string;
  direction?: 'horizontal' | 'vertical';
  onChange: () => void;
};
