import * as i from 'types';
import React from 'react';

import { FormField, Checkbox } from 'common/form';
import { Container } from './styled';

export const InputRadio: React.FC<InputRadioProps> = ({
  items, name, label, error, direction, description, onChange, value,
}) => {
  return (
    <FormField {...{ name, label, error, direction, description }}>
      <Container direction={direction}>
        {items?.map((item) => (
          <Checkbox
            isRadio
            key={item.value}
            label={item.label}
            direction={direction}
            onChange={() => onChange(item.value)}
            isActive={value === item.value}
          />
        ))}
      </Container>
    </FormField>
  );
};

export type InputRadioProps = i.FormFieldProps & {
  items?: i.CheckboxItemType[];
  onChange: (value: string) => void;
  value: string;
  direction?: 'horizontal' | 'vertical';
};
