import * as React from 'react';
import * as i from 'types';

import { ExtendedInput } from './styled';

export const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(({
  onChange, onFocus, onBlur, ...props
}, ref) => {
  const [isActive, setIsActive] = React.useState<boolean>(Boolean(props?.defaultValue));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    setIsActive(Boolean(value));

    if (onChange) onChange(event);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsActive(true);

    if (onFocus) onFocus(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!event.target.value) setIsActive(false);
    if (onBlur) onBlur(event);
  };

  return (
    <ExtendedInput
      {...props}
      ref={ref}
      type="date"
      placeholder="dd-mm-yyyy"
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      isActive={isActive}
    />
  );
});

export type DateInputProps =
  i.DefaultInputProps
  & i.FormFieldProps;
