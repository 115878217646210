import * as i from 'types';
import * as React from 'react';
import { FieldError } from 'react-hook-form';

import { Label } from '../Label';
import { FormFieldDescription } from '../FormFieldDescription';
import { FormFieldContainer, FormFieldsWrapper } from './styled';

export const FormField: React.FC<FormFieldProps> = ({
  children, label, name, error, description, isActive, variant, type,
}) => {
  return (
    <FormFieldContainer>
      <FormFieldsWrapper
        {...{ variant, type }}
      >
        {label && (
          <Label
            htmlFor={name}
            {...{ isActive, variant, type }}
          >
            {label}
          </Label>
        )}
        {children}
      </FormFieldsWrapper>
      {(error || description) && (
        <FormFieldDescription isError={!!error}>
          {error?.message || description || 'Dit veld is verplicht'}
        </FormFieldDescription>
      )}
    </FormFieldContainer>
  );
};

export type FormFieldProps = {
  name?: string;
  isActive?: boolean;
  children?: React.ReactNode;
  label?: string | React.ReactNode;
  description?: string;
  error?: FieldError;
  variant?: 'white';
  type?: i.InputTypes;
};
