import * as i from 'types';
import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import theme from 'styles/theme';

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-family: sans-serif;
  margin: 0;

  ${({ direction }) => direction === 'horizontal' && media.tablet`
    width: auto;
    margin: 0 24px 0 0;

    &:last-of-type {
      margin: 0;
    }
  `}
`;

type CheckboxContainerProps = Pick<i.FormFieldProps, 'direction'>;

export const Container = styled.div<ContainerProps>`
  height: 20px;
  width: 20px;
  border: 1px solid white;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white.off};
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  ${({ isRadio }) => css`
    border-radius: ${isRadio ? '100%' : '2px'};
  `}

  ${({ isActive }) => css`
    background-color: ${isActive ? theme.colors.primary : theme.colors.white.off};
  `}
`;

type ContainerProps = {
  isActive?: boolean;
  isRadio?: boolean;
};

export const Circle = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Label = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 20px;
  margin: 0;
`;

export const Check = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  height: 14px;
  width: 8px;
  margin-top: -4px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.white};
  border-right: 2px solid ${({ theme }) => theme.colors.white};
`;
