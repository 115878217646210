import styled, { css } from 'styled-components';

export const FormFieldDescription = styled.span<FormFieldDescriptionProps>`
  width: 100%;
  margin-top: 8px;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.primary};
  display: inline-block;
  color: ${({ theme }) => theme.colors.white};

  ${({ isError }) => isError && css`
    color: ${({ theme }) => theme.colors.red};
  `}
`;

type FormFieldDescriptionProps = {
  isError?: boolean;
};
