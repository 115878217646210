import * as i from 'types';
import styled, { css } from 'styled-components';

export const Label = styled.label<LabelProps>`
  display: inline-block;
  z-index: 2;
  font-size: 14px;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white.opacity};
  text-transform: uppercase;
  letter-spacing: 1.6px;
  cursor: default;
  position: absolute;
  top: calc(50% - 20px);
  transform: translate(0, 0) scale(1);
  transform-origin: left center;
  transition: transform 0.3s ease;
  line-height: 15.4px;

  ${({ variant }) => (variant === 'white' || variant === 'light') && css`
    color: ${({ theme }) => theme.colors.gray.medium};
    top: calc(60% - 20px);
  `}

  ${({ isActive }) => isActive && css`
    transform: translate(0, -11px) scale(0.9);
    color: ${({ theme }) => theme.colors.white};
  `}

  ${({ isActive, variant }) => (variant === 'white' || variant === 'light') && isActive && css`
    transform: translate(0, -11px) scale(0.9);
    color: ${({ theme }) => theme.colors.black};
  `}

  ${({ type }) => type === 'textarea' && css`
    top: -20px;
  `}
`;

export type LabelProps = {
  htmlFor?: string;
  isActive?: boolean;
  hasDefaultvalue?: boolean;
  hasValue?: boolean;
  variant?: 'white';
  type?: i.InputTypes;
};
