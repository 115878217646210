import * as React from 'react';
import * as i from 'types';

import { Input } from 'common/form';
import { EyeClosed, EyeOpen } from 'common/svg';

export const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(({
  ...props
}, ref) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const eyeIcon = showPassword ?
    <EyeOpen onClick={() => setShowPassword(!showPassword)} /> :
    <EyeClosed onClick={() => setShowPassword(!showPassword)} />;

  return (
    <Input
      {...props}
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      icon={eyeIcon}
      $iconPosition="right"
    />
  );
});

export type PasswordInputProps =
  i.DefaultInputProps
  & i.FormFieldProps;
