import styled from 'styled-components';

export const Container = styled.div<ContainerProps>`
  display: flex;
  gap: 16px;
  flex-direction: ${({ direction }) => direction === 'horizontal' ? 'row' : 'column'};
`;

type ContainerProps = {
  direction?: 'horizontal' | 'vertical';
};
